<template>
  <v-app>
    <v-container class="my-12">
      <v-parallax src="https://medluki.ru/assets/imgVue/MVE_0107.jpg">
        <v-row>
          <v-col align-self="end" class="text-center" cols="12">
            <div class="banner">
              <h1 class="text-h4">
                <strong
                  >Если вам нужна помощь, вы знаете, куда обратиться</strong
                >
              </h1>
              <h4 class="subheading">МЦ Медтайм, С заботой о вас</h4>
            </div>
            <v-btn
              href="https://medluki.ru/raspisanie-priemov.html"
              dark
              class="ma-6"
              color="red"
            >
              Записаться на прием
            </v-btn>
          </v-col>
        </v-row>
      </v-parallax>
      <br />
      <v-container>
        <v-row justify="center">
          <v-col offset="" class="text-center" cols="12" sm="3">
            <v-icon size="100" color="red darken-2">
              mdi-account-multiple
            </v-icon>

            <h1>Более 80000</h1>
            <h4>пациентов получили помощь</h4>
            <v-card-text> </v-card-text>
          </v-col>

          <v-col class="text-center" cols="12" sm="3">
            <v-icon size="100" color="red darken-2"> mdi-medical-bag </v-icon>
            <h1>Более 5000</h1>
            <h4>выполненных операций</h4>
            <v-card-text> </v-card-text>
          </v-col>
          <v-col class="text-center" cols="12" sm="3">
            <v-icon size="100" color="red darken-2">
              mdi-clipboard-check
            </v-icon>
            <h1>44</h1>
            <h4>направления</h4>
            <v-card-text></v-card-text>
          </v-col>
        </v-row>
      </v-container>

      <v-row>
        <v-col class="text-center" cols="12" md="6" sm="12">
          <h2>Видео</h2>
          <br />
          <v-carousel interval="0" height="400">
            <v-carousel-item>
              <v-sheet height="92%">
                <v-row class="fill-height" align="center" justify="center">
                  <iframe
                    width="560"
                    height="349"
                    src="https://www.youtube.com/embed/SWHG4O9JgT8"
                    frameborder="0"
                    allowfullscreen
                  >
                  </iframe>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet height="92%">
                <v-row class="fill-height" align="center" justify="center">
                  <iframe
                    width="560"
                    height="349"
                    src="https://www.youtube.com/embed/B79COMfEgA4"
                    frameborder="0"
                    allowfullscreen
                  >
                  </iframe>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet height="92%">
                <v-row class="fill-height" align="center" justify="center">
                  <iframe
                    width="560"
                    height="349"
                    src="https://www.youtube.com/embed/wEOt3zSkD2E"
                    frameborder="0"
                    allowfullscreen
                  >
                  </iframe>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col class="text-center" cols="12" md="6" sm="12">
          <h2>Галерея</h2>
          <br />
          <v-carousel cycle height="400" show-arrows-on-hover hide-delimiters>
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
      <br />
      <br />
      <hr />
      <br />
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "Main",
  data: () => ({
    numbers: [],
    items: [
      {
        src: "https://medluki.ru/assets/images/galleryNew/big/stomat/1.JPG",
      },
      {
        src: "https://medluki.ru/assets/images/galleryNew/big/cosmet/2.jpg",
      },
      {
        src: "https://medluki.ru/assets/images/galleryNew/big/stomat/5.JPG",
      },
      {
        src: "https://medluki.ru/assets/images/galleryNew/big/cosmet/3.jpg",
      },
    ],
  }),
  mounted() {
    axios
      .get("https://medluki.ru/assets/vueModules/numbers.json")
      .then((response) => {
        this.wholeResponse = response.data;
        console.log(this.wholeResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style>
h1 {
  text-shadow: 1px 1px 1px #000;
}
.banner {
  background-color: rgba(0, 0, 0, 0.2);
}
hr {
  opacity: 30%;
}
</style>
